import isClient from 'utils/envs/isClient'

const storage = {
  _storage: {},
  getItem(key) {
    return this._storage[key]
  },
  setItem(key, value) {
    this._storage[key] = value
  },
  removeItem(key) {
    this._storage = Object.keys(this._storage)
      .filter((_key) => key !== _key)
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: this._storage[key]
        }
      }, {})
  },
  clear() {
    this._storage = {}
  },
  key(index) {
    return Object.keys(this._storage)[index]
  },
  length() {
    return Object.keys(this._storage).length
  }
}

export default {
  local:
    isClient() && window.localStorage ? window.localStorage : { ...storage },
  session:
    isClient() && window.sessionStorage ? window.sessionStorage : { ...storage }
}
