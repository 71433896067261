import { createContext, useCallback, useContext, useState } from 'react'

import storage from 'utils/storage'

const storageName = `${APP_NAME}_${BUILD_ENV}`

const LeadContext = createContext({})

const loadState = (state) => {
  return JSON.parse(storage.session.getItem(`${storageName}_${state}`) ?? '{}')
}

const LeadProvider = ({ children }) => {
  const [leadData, setLeadData] = useState(loadState('leadData'))

  const addLeadData = useCallback((payload) => {
    const data = {
      ...loadState('leadData'),
      ...payload
    }
    setLeadData(data)
    storage.session.setItem(`${storageName}_leadData`, JSON.stringify(data))
  }, [])

  const removeLeadData = useCallback((payload) => {
    const payloadArray = Array.isArray(payload) ? payload : [payload]

    const removedData = payloadArray.reduce((acc, key) => {
      return {
        ...acc,
        [key]: undefined
      }
    }, {})

    const data = {
      ...loadState('leadData'),
      ...removedData
    }

    setLeadData(data)
    storage.session.setItem(`${storageName}_leadData`, JSON.stringify(data))
  }, [])

  return (
    <LeadContext.Provider
      value={{
        leadData,
        addLeadData,
        removeLeadData
      }}
    >
      {children}
    </LeadContext.Provider>
  )
}

const useLead = () => {
  const context = useContext(LeadContext)

  if (!context) {
    throw new Error('useLead must be used within a LeadProvider')
  }

  return context
}

export { LeadProvider, useLead }
