const loadScript = (src, id, defer = true, appendOn = 'body') => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = src
    script.id = id
    script.defer = defer
    script.onload = resolve
    script.onerror = reject

    if (appendOn === 'body') {
      document.body.append(script)
    } else {
      document.head.append(script)
    }
  })
}

export default loadScript
