import axios from 'axios'

import sprite from 'assets/svg/sprite.svg'

const insertSpriteOnPage = ({ data }) => {
  return new Promise((resolve) => {
    const wrapper = document.createElement('div')
    wrapper.classList.add('svg-sprite-wrapper')
    wrapper.innerHTML = data
    document.body.insertBefore(wrapper, document.body.childNodes[0])

    resolve()
  })
}

;(() => {
  const options = {
    url: sprite
  }

  const onLoadSuccess = () => console.log('SVG sprite loaded successfully')
  const onLoadFail = () => console.log('SVG sprite load failed')

  axios(options).then(insertSpriteOnPage).then(onLoadSuccess).catch(onLoadFail)
})()
