import FontFaceObserver from 'fontfaceobserver'

const fonts = [
  {
    name: 'Itau Text',
    weight: 300
  },
  {
    name: 'Itau Text',
    weight: 400
  },
  {
    name: 'Itau Text',
    weight: 600
  }
]

;(() => {
  const toFontFaceObserver = (font) =>
    new FontFaceObserver(font.name, { ...font }).load()

  const onFontLoad = () => document.body.classList.add('-fonts-loaded')
  const onFontFail = (err) => console.error(`Error when loading fonts: ${err}`)

  const asyncFonts = fonts.map(toFontFaceObserver)

  return Promise.all(asyncFonts).then(onFontLoad).catch(onFontFail)
})()
