import storage from 'utils/storage'

const customLayerSchema = (currentStep) => {
  const parc = Number(storage.session.getItem('parc') ?? '')
  const limof = Number(storage.session.getItem('limof') ?? '')
  const volsim = Number(storage.session.getItem('volsim') ?? '')
  const idproposta = storage.session.getItem('idproposta') ?? ''
  const prazofinanciamento = Number(
    storage.session.getItem('prazofinanciamento') ?? ''
  )

  const trackingEmpty = {}

  const trackingLevel1 = {
    custom: {
      idproposta
    }
  }

  const trackingLevel2 = {
    visitor: {
      prazofinanciamento
    },
    custom: {
      idproposta,
      volsim,
      parc,
      limof
    }
  }

  const customLayerSchema = {
    Home: trackingEmpty,
    DadosPessoais: trackingEmpty,
    Token: trackingEmpty,
    ConsultandoCredito: trackingEmpty,
    'proposta/abra-sua-conta': trackingLevel1,
    Resumo: trackingLevel1,
    EditarSimulacao: trackingLevel1,
    RedirectApp: trackingLevel1,
    Sucesso: trackingLevel2
  }

  return customLayerSchema[currentStep] ?? {}
}

export default customLayerSchema
