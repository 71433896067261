import utmQueriesService from 'services/utmQueries'

import isClient from 'utils/envs/isClient'
import storage from 'utils/storage'

export const getSourceUrl = () => {
  if (isClient()) {
    return JSON.parse(storage.session.getItem('source_url'))
  }
}

export const setSourceUrl = () => {
  const sourceUrl = getSourceUrl()

  if (isClient() && !sourceUrl) {
    storage.session.setItem('source_url', JSON.stringify(window.location.href))

    const { utm_source } = utmQueriesService.get()

    if (utm_source === 'pivo') {
      storage.session.setItem('is_pivo', JSON.stringify(true))
    }
  }
}
