// import Tracking from 'components/Tracking'
import { ContrastProvider } from 'hooks/useContrast'
import { LayoutProvider } from 'hooks/useLayout'
import { LeadProvider } from 'hooks/useLead'

const wrapWithProvider = ({ element }) => {
  return (
    <ContrastProvider>
      <LayoutProvider>
        <LeadProvider>
          {/* segment rollback */}
          {/* <Tracking>{element}</Tracking> */}
          {element}
        </LeadProvider>
      </LayoutProvider>
    </ContrastProvider>
  )
}

export default wrapWithProvider
