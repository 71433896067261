import { createContext, useContext, useState } from 'react'

const LayoutContext = createContext({})

const LayoutProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('Empréstimo fácil e rápido | iti')

  return (
    <LayoutContext.Provider
      value={{
        pageTitle,
        setPageTitle
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

const useLayout = () => {
  const context = useContext(LayoutContext)

  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider')
  }

  return context
}

export { LayoutProvider, useLayout }
