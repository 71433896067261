import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import storage from 'utils/storage'
import ga from 'utils/thirdParty/ga'

const ContrastContext = createContext({})

const ContrastProvider = ({ children }) => {
  const [isContrastActive, setIsContrastActive] = useState(
    storage.local.getItem('iti_loans_contrast') === 'true'
  )

  useEffect(() => {
    storage.local.setItem('iti_loans_contrast', isContrastActive)
    const root = document.querySelector('html')

    if (isContrastActive) {
      root.setAttribute('app-theme', 'contrast')
    } else {
      root.setAttribute('app-theme', 'default')
    }
  }, [isContrastActive])

  const toggleContrast = useCallback(() => {
    setIsContrastActive((state) => !state)
    ga.trackClick('InversaodeCor')
  }, [])

  return (
    <ContrastContext.Provider value={{ isContrastActive, toggleContrast }}>
      {children}
    </ContrastContext.Provider>
  )
}

const useContrast = () => {
  const context = useContext(ContrastContext)

  if (!context) {
    throw new Error('useContrast must be used within a ContrastProvider')
  }
  return context
}

export { ContrastProvider, useContrast }
