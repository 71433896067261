const createDataLayer = (eventName) => {
  const label = 'BTN:CrediarioIti:' + eventName

  console.log(`[ GA ] - ${label}`)

  return {
    events: {
      category: 'Clique',
      action: 'CrediarioIti',
      label,
      noInteraction: false
    },
    rule: 'customLink'
  }
}

export default createDataLayer
