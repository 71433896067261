import { GA } from 'gatsby-env-variables'

import loadScript from 'utils/DOM/loadScript'
import isClient from 'utils/envs/isClient'

const scripts = [
  {
    id: 'importLibJSFrameworkDA',
    src: `https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/importLibJSFrameworkDA.js?${GA}`
  },
  {
    id: 'frameworkDA',
    src: `https://www.itau.com.br/_arquivosestaticos/Itau/defaultTheme/js/da/framework/frameworkDA.js?${GA}`
  }
]

const init = () => {
  if (isClient()) {
    const scriptsToLoad = scripts.map((script) =>
      loadScript(script.src, script.id, true, false)
    )
    return Promise.all(scriptsToLoad)
  }

  return Promise.reject().catch(() => {
    console.error('Cannot load Google Analytics')
  })
}

export default init
