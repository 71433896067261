import init from './init'

const ga = init()

const dispatch = (payload = {}) => {
  return ga.then(() => {
    window.analyticsData = { ...payload }
    window._frameworkDA.Track()
    console.log('[ GA ] - AnalyticsData applied.')
  })
}

export default dispatch
