import mapDataToCustomLayer from './customLayer'
import dispatch from './dispatch'

const createDataLayer = (pageName, customLayer = {}) => {
  const defaultLayer = {
    site: {
      negocio: 'PF',
      tipoDeCanal: 'Web'
    },
    page: {
      nome: pageName
    },
    rule: 'pageLoad',
    ...customLayer
  }

  return defaultLayer
}
const trackPagename = (currentStep) => {
  const pageName = `IT:NL:NCC:Emprestimo:CrediarioIti:${currentStep}`

  console.log(`[ GA ] - ${pageName}`)

  const customLayer = mapDataToCustomLayer(currentStep)
  const dataLayer = createDataLayer(pageName, customLayer)

  return dispatch(dataLayer)
}

export default trackPagename
